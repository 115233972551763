<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="900px">
        <v-card>
          <v-card-title>
            <span class="headline">Ajuste a Pedido {{ tankID.code }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <p v-if="responseError && !valid">
                Los datos enviados no son correctos, haga click sobre la X de
                los campos inválidos para intentar de nuevo.
              </p>
              <v-form ref="myform" v-model="valid" :lazy-validation="false">
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="tankID.fuel"
                      label="Cantidad"
                      :rules="valueRules"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="adjustment.quantity"
                      label="Cantidad"
                      :rules="valueRules"
                      :error-messages="quantityError"
                      @click:clear="quantityError = []"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      label="Descripción"
                      v-model="adjustment.observations"
                      :error-messages="observationsError"
                      @click:clear="observationsError = []"
                      :rules="onlyRequired"
                      clearable
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close"> Cerrar </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="create()"
              :disabled="!valid"
              :loading="loading"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules";
export default {
  props: {
    dialog: { type: Boolean, required: true },
    tankID: { type: Object, required: true },
  },
  data: () => {
    return {
      loading: false,
      responseError: false,
      observationsError: "",
      quantityError: "",
      valid: false,
      adjustment: {
        fueltank_id: undefined,
        quantity: undefined,
        fuel_tank_id: undefined,
      },
      //rules
      onlyRequired: [rules.required],
      valueRules: [rules.required],
    };
  },
  methods: {
    close() {
      this.$refs.myform.reset();
      this.$emit("close");
      this.$emit("update");
    },
    create() {
      this.valid = false;
      this.loading = true;
      if (this.$refs.myform.validate()) {
        this.adjustment.fueltank_id = this.tankID.id;
        requests.post("/api/adjustment/", this.adjustment).then((res) => {
          if (res.status == 201) {
            this.$toasted.global.info({
              message: "Operación realizada con éxito.",
            });
            this.close();
            this.$emit("update");
          } else if (res.status == 400) {
            this.responseError = true;
            if (res.data.quantity != undefined) {
              this.quantityError = res.data.quantity;
            }
            if (res.data.observations != undefined) {
              this.observationsError = res.data.observations;
            }

            if (res.data.detail != undefined) {
              this.$toasted.global.info({
                message: res.data.detail,
              });
            }
          } else {
            console.log(res);
          }
        });
        this.loading = false;
      }
    },
  },
  watch: {},
};
</script>